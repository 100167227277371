<template>
  <div class="card-v2">
    <div class="card-v2__top">
      <span class="card-v2__icon"><slot name="icon" /></span>
      <span class="card-v2__title"><slot name="title" /></span>
    </div>
    <!-- /.card__top -->

    <p class="card-v2__text">
      <slot name="text" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'CardV2',
};
</script>

<style lang="scss" scoped>
@import "./card-v2.scss";
</style>
