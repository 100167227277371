import IconsPackMobile from '@/assets/icons/morefeatures/mobile/icons-pack-mobile';
import IconsPackDesktop from '@/assets/icons/morefeatures/desktop/icons-pack-desktop';

const MoreFeaturesIconsDesktop = IconsPackDesktop;

const MoreFeaturesIconsMobile = IconsPackMobile;

export {
  MoreFeaturesIconsDesktop,
  MoreFeaturesIconsMobile,
};
