<template>
  <div class="main">
    <Hero />
    <div class="container">
      <WhyApps />
    </div>
    <!-- /.container -->
    <p class="horizontal-line"/>
    <ReadyToUse />
    <p class="horizontal-line"/>
    <div class="container">
      <Features />
    </div>
    <!-- /.container -->
    <p class="horizontal-line"/>
    <Design />
    <p class="horizontal-line"/>
    <div class="container">
      <TechSolutions />
    </div>
    <!-- /.container -->
    <p class="horizontal-line"/>
    <Subscription />
    <p class="horizontal-line"/>
    <div class="container">
      <MoreFeatures />
    </div>
    <!-- /.container -->
    <p class="horizontal-line"/>
    <FooterCta />
    <Modal/>
  </div>
</template>

<script>
import Hero from '@/components/Hero/Hero';
import WhyApps from '@/components/WhyApps/WhyApps';
import ReadyToUse from '@/components/ReadyToUse/ReadyToUse';
import Features from '@/components/Features/Features';
import Design from '@/components/Design/Design';
import TechSolutions from '@/components/TechSolutions/TechSolutions';
import Subscription from '@/components/Subscription/Subscription';
import MoreFeatures from '@/components/MoreFeatures/MoreFeatures';
import FooterCta from '@/components/FooterCTA/FooterCta';
import Modal from '@/components/Modal/Modal';

export default {
  name: 'MainContainer',
  components: {
    Modal,
    Hero,
    WhyApps,
    ReadyToUse,
    Features,
    Design,
    TechSolutions,
    Subscription,
    MoreFeatures,
    FooterCta,
  },
};
</script>

<style scoped lang="scss">
@import "./main.scss";
</style>
