<i18n src="../i18n/gamification.json"></i18n>

<template>
  <BasePage>
    <template #caption>
      <UBadge
        type="primary"
        size="sm"
        :title="uBadge.title"
        :icon-inside="uBadge.iconInside"
        style="display: inline-block;"
      />
    </template>

    <template #h1>{{ $t('title') }}</template>

    <template #aside>
      <div class="cols-2 mb-16">
        <CardV2>
          <template #icon><i class="icon-game-controller"></i></template>
          <template #title>{{ $t('gamification') }}</template>
          <template #text>{{ $t('gamification-text') }}</template>
        </CardV2>

        <CardV2>
          <template #icon><i class="icon-puzzle text-purple"></i></template>
          <template #title>{{ $t('additional_module2') }}</template>
          <template #text>{{ $t('additional_module2-text') }}</template>
        </CardV2>
      </div>
    </template>

    <SecCard
      v-for="(sec, l) in contents"
      :key="'sec_'+l"
      :img="sec.img"
      :reverse="sec.reverse"
    >
      <template #caption>
        <span :style="{color: sec.color}">
          <i :class="[sec.icon]"></i>
          {{ sec.caption }}
        </span>
      </template>
      <template #title>{{ sec.title }}</template>
      <template #text><span v-html="sec.text"></span></template>
    </SecCard>

    <template #preend>
      <p class="horizontal-line" />
      <FooterCta />
      <Modal />
    </template>
  </BasePage>
</template>

<script>
import UBadge from '@/components/U/Badge/UBadge.vue';
import MoreFeaturesIconsDesktop from '@/assets/icons/morefeatures/desktop/icons-pack-desktop';
import MoreFeaturesIconsMobile from '@/assets/icons/morefeatures/mobile/icons-pack-mobile';
import SecCard from '@/components/SecCard/SecCard.vue';
import BasePage from '@/components/BasePage/BasePage.vue';
import FooterCta from '@/components/FooterCTA/FooterCta.vue';
import Modal from '@/components/Modal/Modal.vue';
import CardV2 from '@/components/U/CardV2/CardV2.vue';

export default {
  name: 'GamificationView',

  components: {
    BasePage,
    UBadge,
    CardV2,
    SecCard,
    FooterCta,
    Modal,
  },

  computed: {
    contents() {
      return [
        {
          caption: this.$t('card-1-caption'),
          title: this.$t('card-1-title'),
          text: this.$t('card-1-text'),
          img: this.$t('image'),
          color: '#14B8A6',
          icon: 'icon-pool-8-ball',
        },
        {
          caption: this.$t('card-2-caption'),
          title: this.$t('card-2-title'),
          text: this.$t('card-2-text'),
          img: this.$t('image-1'),
          reverse: true,
          color: '#10B981',
          icon: 'icon-message-question-circle',
        },
        {
          caption: this.$t('card-3-caption'),
          title: this.$t('card-3-title'),
          text: this.$t('card-3-text'),
          img: this.$t('image-2'),
          color: '#A855F7',
          icon: 'icon-scale',
        },
        {
          caption: this.$t('card-4-caption'),
          title: this.$t('card-4-title'),
          text: this.$t('card-4-text'),
          img: this.$t('image-3'),
          reverse: true,
          color: '#06B6D4',
          icon: 'icon-trophy',
        },
      ];
    },

    uBadge() {
      return {
        title: this.$t('additional_module'),
        iconInside: MoreFeaturesIconsDesktop.Puzzle,
        iconOutside: MoreFeaturesIconsMobile.Puzzle,
      };
    },
  },
};
</script>
