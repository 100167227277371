<template>
  <div class="icons-collage">
    <div
      v-for="(icon, i) in icons"
      :key="i"
      :style="{
        top: icon.top,
        left: icon.left
      }"
      class="icons-collage__icon"
    >
      <img :src="icon.icon" :width="icon.width" alt="">
    </div>

    <div class="icons-collage__content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconsCollage',
  props: {
    icons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
@import "./icons-collage.scss";
</style>
