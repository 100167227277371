<i18n src="./translations.json"></i18n>

<template>
  <div v-if="isShow" class="footer-cta">
    <img src="@/assets/img/footercta/bg-image.png" alt="" class="footer-cta__background">
    <img src="@/assets/img/footercta/bg-image-mobile.png" alt=""
         class="footer-cta__background--mobile">
    <div class="footer-cta__bg"/>
    <img src="@/assets/img/footercta/icons-1-mob.png" alt="" class="footer-cta__icon--mob">
    <IconsCollage :icons="icons">
      <div class="footer-cta__text">
        <component :is="logo"/>
        <p class="footer-cta__text-title">
          {{ $t('title') }}
        </p>
      </div>
      <UButton
        theme="success"
        size="md"
        easy-add="#modal"
        easy-class="modal--show"
      >
        {{ $t('action') }}
      </UButton>
    </IconsCollage>
    <img src="@/assets/img/footercta/icons-2-mob.png" alt="" class="footer-cta__icon--mob">
  </div>
</template>

<script>
import UButton from '@/components/U/Button/UButton';
import IconsCollage from '@/components/IconsCollage/IconsCollage';
import { FooterCtaIconsMobile, FooterCtaIconsDesktop } from '@/assets/icons/footercta/icons-pack';

export default {
  name: 'FooterCta',
  components: { UButton, IconsCollage, FooterCtaIconsDesktop },
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      logo: FooterCtaIconsDesktop.CrespoLogo,
      logoMini: FooterCtaIconsMobile.CrespoLogo,
      icons: [
        {
          icon: '/img/1-min.png',
          left: '116px',
          top: '-67px',
          leftM: '144px',
          topM: '380px',
          width: '100px',
        },
        {
          icon: '/img/2-min.png',
          left: '1028.58px',
          top: '-35px',
          leftM: '0',
          topM: '-84px',
          width: '124px',
        },
        {
          icon: '/img/3-min.png',
          left: '17px',
          top: '116px',
          leftM: '255px',
          topM: '-91px',
          width: '114px',
        },
        {
          icon: '/img/4-min.png',
          left: '1118px',
          top: '125px',
          leftM: '155px',
          topM: '-120px',
          width: '104px',
        },
        {
          icon: '/img/5-min.png',
          left: '103px',
          top: '310px',
          leftM: '0',
          topM: '330px',
          width: '100px',
        },
        {
          icon: '/img/6-min.png',
          left: '1033px',
          top: '315px',
          leftM: '255px',
          topM: '349px',
          width: '104px',
        },
      ],
    };
  },
  methods: {
    onClick() {
    },
  },
};
</script>

<style scoped lang="scss">
@import "./footer-cta.scss";
</style>
