<i18n src="./translations.json"></i18n>

<template>
  <section class="section design">
    <div class="design__heading-container">
      <div class="design__heading">
        <h2 class="section__title">
          {{ $t('title') }}
        </h2>
        <span class="section__subtitle">
          {{ $t('subtitle') }}
        </span>
      </div>
      <div class="container">
        <UTabs :tabs="tabs">
          <template #dinamo>
            <div class="design__image">
              <img src="@/assets/img/design/image-bg-blue.png" alt="" class="design__background">
              <img :src="$t('tab_img1')" alt="" class="design__image-screens">
            </div>
          </template>
          <template #club2>
            <div class="design__image">
              <img src="@/assets/img/design/image-bg-red.png" alt="" class="design__background">
              <img :src="$t('tab_img2')" alt="" class="design__image-screens">
            </div>
          </template>
          <template #club3>
            <div class="design__image">
              <img src="@/assets/img/design/image-bg-green.png" alt="" class="design__background">
              <img :src="$t('tab_img3')" alt="" class="design__image-screens">
            </div>
          </template>
        </UTabs>
      </div>
    </div>
    <UButton
      theme="secondary"
      :size="'lg'"
      easy-add="#modal"
      easy-class="modal--show"
      class="design__button"
    >
      {{ $t('action') }}
      <component :is="iconsDesktop.Right"/>
    </UButton>
  </section>
</template>

<script>
import UButton from '@/components/U/Button/UButton';
import { DesignIconsDesktop, DesignIconsMobile } from '@/assets/icons/design/icons-pack';
import UTabs from '@/components/U/Tabs/UTabs';

export default {
  name: 'DesignContainer',
  components: { UButton, UTabs },
  data() {
    return {
      iconsDesktop: DesignIconsDesktop,
      iconsMobile: DesignIconsMobile,
      selectedTab: null,
    };
  },
  computed: {
    selectedTabContent() {
      return this.selectedTab?.content || this.tabs[0].content;
    },

    tabs() {
      return [
        {
          id: 0,
          title: 'dinamo',
          label: this.$t('dinamo'),
          labelMini: this.$t('dinamo'),
          icon: DesignIconsDesktop.Dinamo,
          iconMini: DesignIconsMobile.Dinamo,
          selected: true,
          content: {
            screens: 'dinamo',
          },
        },
        {
          id: 1,
          title: 'club2',
          label: this.$t('club2'),
          labelMini: this.$t('club2'),
          icon: DesignIconsDesktop.Club,
          iconMini: DesignIconsMobile.Club,
          selected: false,
          content: {
            screens: 'club-red',
          },
        },
        {
          id: 2,
          title: 'club3',
          label: this.$t('club3'),
          labelMini: this.$t('club3'),
          icon: DesignIconsDesktop.Club,
          iconMini: DesignIconsMobile.Club,
          selected: false,
          content: {
            screens: 'club-black',
          },
        },
      ];
    },
  },
  methods: {
    tabClick(tab) {
      this.selectedTab = tab;
      this.tabs = this.tabs.map((item) => (item.id === tab.id
        ? { ...item, selected: true }
        : { ...item, selected: false }));
    },
    getSrc(name) {
      const images = require.context('@/assets/img/design/', false, /\.png$/);
      return images(`./${name}.png`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./design.scss";
</style>
