<template>
  <label
    for="input"
    :class="[`u-input u-input__type-${type}`]"
  >
    <span class="u-input__label">{{ label }}</span>

    <textarea
      v-if="type === 'textarea'"
      :class="[
        'u-input__field' ,
        { 'u-input__field--error': message }
      ]"
      :rows="rows"
      :placeholder="placeholder"
      :disabled="disabled"
      name="text"
      :value="newValue"
      @keyup="updateValue"
    />
    <input
      v-else-if="type==='tel'"
      :class="[
        'u-input__field',
        { 'u-input__field--error': message }
      ]"
      :placeholder="placeholder"
      :disabled="disabled"
      :type="type"
      :value="newValue"
      @keyup="updateValue"
      v-maska:[maskOptions]
    />
    <input
      v-else
      :class="[
        'u-input__field',
        { 'u-input__field--error': message }
      ]"
      :placeholder="placeholder"
      :disabled="disabled"
      :type="type"
      :value="newValue"
      @keyup="updateValue"
    />
    <span class="u-input__message">{{ message }}</span>
  </label>
</template>

<script>
import { vMaska } from 'maska';
import phoneMasks from './phone-masks';

export default {
  name: 'UInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    message: {
      type: [String, Object, Function, Boolean],
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    type: {
      type: String,
      default: 'text',
      validate: (value) => ['text', 'email', 'tel', 'password', 'textarea'].includes(value),
    },
  },
  directives: { maska: vMaska },
  data() {
    return {
      newValue: this.value,
      maskInput: null,
    };
  },
  methods: {
    updateValue(e) {
      this.newValue = e.target.value;
      this.$emit('input', this.newValue);
    },
    maskUpdate(value) {
      let matrix = '+###############';

      phoneMasks.forEach((item) => {
        const mask = item.mask.replace(/[\s#()/-]/g, '');
        const phone = value.replace(/[\s#)(]/g, '');

        if (phone.includes(mask)) {
          matrix = item.mask;
        }
      });
      return matrix;
    },
  },

  computed: {
    maskOptions() {
      return {
        mask: (value) => this.maskUpdate(value),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "./u-input.scss";
</style>
