import { render, staticRenderFns } from "./PolicyView.vue?vue&type=template&id=ec6915c4&"
import script from "./PolicyView.vue?vue&type=script&lang=js&"
export * from "./PolicyView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../i18n/policy.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FPolicyView.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports