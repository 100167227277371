<template>
  <button
    class="u-button-menu"
    @click="$emit('toggle-menu')"
  >
    <span
      class="u-button-menu__icon"
      :class="{'u-button-menu__icon--close': showMenu}"
    >
      <span/>
    </span>
  </button>
</template>

<script>

export default {
  props: {
    showMenu: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./u-button-menu.scss";
</style>
