import IconsPackMobile from '@/assets/icons/design/mobile/icons-pack-mobile';
import IconsPackDesktop from '@/assets/icons/design/desktop/icons-pack-desktop';

const DesignIconsDesktop = IconsPackDesktop;

const DesignIconsMobile = IconsPackMobile;

export {
  DesignIconsDesktop,
  DesignIconsMobile,
};
