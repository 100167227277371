import Vue from 'vue';
import VueI18n from 'vue-i18n';
import easySetup from 'easy-toggler';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/common.scss';

easySetup({
  // html attrs
  toggle: 'easy-toggle',
  add: 'easy-add',
  remove: 'easy-remove',
  class: 'easy-class',
  rcoe: 'easy-rcoe',
  parallel: 'easy-parallel',
  self: 'easy-self',
  selfRcoe: 'easy-self-rcoe',

  // Hooks
  // onToggle($el) {},
  // onAdd($el) {},
  // onRemove($el) {},
  // onRcoe($el) {},
});

Vue.use(VueI18n);

Vue.config.productionTip = false;

const i18n = new VueI18n();

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
