<template>
  <Main />
</template>

<script>
import Main from '@/components/Main/Main';

export default {
  name: 'HomeView',
  components: { Main },
};
</script>
