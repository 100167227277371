import { render, staticRenderFns } from "./WhyApps.vue?vue&type=template&id=0387e96c&scoped=true&"
import script from "./WhyApps.vue?vue&type=script&lang=js&"
export * from "./WhyApps.vue?vue&type=script&lang=js&"
import style0 from "./WhyApps.vue?vue&type=style&index=0&id=0387e96c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0387e96c",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FWhyApps%2FWhyApps.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports