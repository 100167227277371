import CrespoLogo from './icons/Crespo-Logo.svg';
import Fc1 from './icons/fc-1.svg';
import Fc2 from './icons/fc-2.svg';
import Fc3 from './icons/fc-3.svg';
import Fc4 from './icons/fc-4.svg';
import Fc5 from './icons/fc-5.svg';
import Fc6 from './icons/fc-6.svg';

const FooterCTAIconsMobile = Object.freeze({
  CrespoLogo,
  Fc1,
  Fc2,
  Fc3,
  Fc4,
  Fc5,
  Fc6,
});

export default FooterCTAIconsMobile;
