<i18n src="./translations.json"></i18n>

<template>
  <section class="more-features">
    <div class="flex column items-center gap-104">
      <div class="more-features__heading flex column items-center gap-24">
        <h2 class="section__title">
          {{ $t('title') }}
        </h2>
        <p class="more-features__heading-description">
          {{ $t('subtitle') }}
        </p>
      </div>

      <div class="more-features__grid">
        <UCard
          class="more-features__grid-item"
          :key="i"
          v-for="(item, i) in cards"
          :content="item"
          @on-click="onClick"
        />
    </div>
    </div>
  </section>
</template>

<script>
import UCard from '@/components/U/Card/UCard';
import {
  MoreFeaturesIconsDesktop,
  MoreFeaturesIconsMobile,
} from '@/assets/icons/morefeatures/icons-pack';

export default {
  name: 'MoreFeaturesContainer',
  components: { UCard },
  computed: {
    cards() {
      return [
        {
          uCard: {
            title: this.$t('card-1-title'),
            url: '/augmented-reality',
            description: this.$t('card-1-text'),
            background: this.$t('image-bg-1'),
            overlay: true,
            size: 'lg',
          },
          uBadge: {
            title: this.$t('added_module'),
            type: 'primary',
            size: 'sm',
            iconInside: MoreFeaturesIconsDesktop.Puzzle,
            iconInsideMini: MoreFeaturesIconsMobile.Puzzle,
            iconOutside: null,
            iconOutsideMini: null,
          },
          uButton: {
            icon: MoreFeaturesIconsDesktop.ArrowRight,
            iconMini: MoreFeaturesIconsMobile.ArrowRight,
            title: this.$t('more'),
            type: 'secondary',
          },
        },
        {
          uCard: {
            title: this.$t('card-2-title'),
            url: '/gamification',
            description: this.$t('card-2-text'),
            background: this.$t('image-bg-2'),
            overlay: true,
            size: 'lg',
          },
          uBadge: {
            title: this.$t('added_module'),
            type: 'primary',
            size: 'sm',
            iconInside: MoreFeaturesIconsDesktop.Puzzle,
            iconInsideMini: MoreFeaturesIconsMobile.Puzzle,
            iconOutside: null,
            iconOutsideMini: null,
          },
          uButton: {
            icon: MoreFeaturesIconsDesktop.ArrowRight,
            iconMini: MoreFeaturesIconsMobile.ArrowRight,
            title: this.$t('more'),
            type: 'secondary',
          },
        },
        {
          uCard: {
            title: this.$t('card-3-title'),
            url: '',
            description: this.$t('card-3-text'),
            background: '',
            overlay: false,
            size: 'md',
          },
          uBadge: {
            title: '24/7',
            type: 'success',
            size: 'sm',
            iconInside: null,
            iconInsideMini: null,
            iconOutside: MoreFeaturesIconsDesktop.Support,
            iconOutsideMini: MoreFeaturesIconsMobile.Support,
          },
          uButton: null,
        },
        {
          uCard: {
            title: this.$t('card-4-title'),
            url: '',
            description: this.$t('card-4-text'),
            background: '',
            overlay: false,
            size: 'md',
          },
          uBadge: {
            title: '',
            type: 'success',
            size: 'sm',
            iconInside: null,
            iconInsideMini: null,
            iconOutside: MoreFeaturesIconsDesktop.CurrencyDollar,
            iconOutsideMini: MoreFeaturesIconsMobile.CurrencyDollar,
          },
          uButton: null,
        },
        {
          uCard: {
            title: this.$t('card-5-title'),
            url: '',
            description: this.$t('card-5-text'),
            background: '',
            overlay: false,
            size: 'md',
          },
          uBadge: {
            title: this.$t('in-dev'),
            type: 'secondary',
            size: 'sm',
            iconInside: MoreFeaturesIconsDesktop.PuzzlePiece01,
            iconInsideMini: MoreFeaturesIconsMobile.PuzzlePiece01,
            iconOutside: MoreFeaturesIconsDesktop.ReceiptTax,
            iconOutsideMini: MoreFeaturesIconsMobile.ReceiptTax,
          },
          uButton: null,
        },
      ];
    },
  },
  methods: {
    onClick(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./more-features.scss";
</style>
