<i18n src="./translations.json"></i18n>

<template>
  <div class="timeline">
    <p class="timeline__heading">
      {{ $t('heading') }}
    </p>

    <TagsSlider />

    <p
      v-html="$t('text')"
      class="timeline__text"
    />
  </div>
</template>

<script>
import TagsSlider from '@/components/TagsSlider/TagsSlider';

export default {
  name: 'TimeLine',
  components: { TagsSlider },
};
</script>

<style scoped lang="scss">
@import "./timeline.scss";
</style>
