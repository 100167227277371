<i18n src="./translations.json"></i18n>

<template>
  <div class="subscription">
    <img src="@/assets/img/subscriptions/subscription-bg.png" alt=""
         class="subscription__background">
    <img src="@/assets/img/subscriptions/subscription-bg-mobile.png" alt=""
         class="subscription__background--mobile">

    <div class="container">
      <div class="subscription__border-container">
        <div class="subscription__container">
          <div class="subscription__text">
            <div class=" subscription__header">
              <p class="subscription__caption">
                {{ $t('caption') }}
              </p>
              <p class="subscription__title">
                {{ $t('title') }}
                <span> {{ $t('price') }}</span>
              </p>
            </div>
            <UButton
              theme="success"
              size="md"
              easy-add="#modal"
              easy-class="modal--show"
            >
              {{ $t('action') }}
            </UButton>
          </div>
          <img src="@/assets/img/subscriptions/cta.png" alt=""
               class="subscription__image-container">
        </div>
      </div>
      <!-- /.container -->
    </div>
  </div>
</template>

<script>
import UButton from '@/components/U/Button/UButton';

export default {
  name: 'SubscriptionContainer',
  components: { UButton },
  methods: {
    onClick() {
      this.requestPriceList();
    },
    requestPriceList() {
    },
  },
};
</script>

<style scoped lang="scss">
@import "./subscription.scss";
</style>
