<i18n src="./translations.json"></i18n>

<template>
  <div class="feedback-form">
    <form
      v-if="!formSended"
      class="feedback-form__container"
      @submit.prevent.stop="onSubmit"
    >
      <UInput
        v-model.trim="$v.form.name.$model"
        type="text"
        :label="$t('name')"
        :placeholder="$t('p_name')"
        :message="nameMsg"
      />

      <div class="w-full feedback-form__wrapper">
        <UInput
          v-model.trim="$v.form.email.$model"
          type="email"
          :label="$t('email')"
          :placeholder="$t('p_email')"
          :message="emailMsg"
        />

        <UInput
          v-model.trim="$v.form.phone.$model"
          type="tel"
          :label="$t('phone')"
          :placeholder="$t('p_phone')"
          :message="phoneMsg"
        />
      </div>

      <UInput
        v-model.trim="form.message"
        type="textarea"
        :label="$t('message')"
        :placeholder="$t('p_message')"
      />

      <UButton
        type="submit"
        class="w-full"
        theme="success"
        size="md"
        :disabled="formLoading"
      >
        <span v-if="!formLoading">{{ $t('submit') }}</span>
        <span v-else>{{ $t('form_loading') }}</span>
      </UButton>
    </form>

    <div v-else class="feedback-form__success">
      <component :is="iconSuccess"/>
      <span>
        {{ $t('success_msg') }}
      </span>
    </div>
    <!-- /.feedback-form__success -->
  </div>
</template>

<script>
import UInput from '@/components/U/Input/UInput';
import UButton from '@/components/U/Button/UButton';
import Success from '@/assets/img/succses/icon.svg';
import axios from 'axios';
import qs from 'qs';
import { validationMixin } from 'vuelidate';
import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';

export default {
  name: 'FeedbackForm',
  mixins: [validationMixin],
  components: { UInput, UButton },

  data() {
    return {
      iconSuccess: Success,
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      formSended: false,
      formLoading: false,
    };
  },

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(5000),
      },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      phone: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      message: {
        maxLength: maxLength(5000),
      },
    },
  },

  computed: {
    nameMsg() {
      if (this.$v.form.name.$error) {
        return this.$t('required_name');
      }
      return '';
    },
    emailMsg() {
      if (this.$v.form.email.$error) {
        if (!this.$v.form.email.required) { return this.$t('required_email'); }
        if (!this.$v.form.email.email) { return this.$t('type_email'); }
      }
      return '';
    },
    phoneMsg() {
      if (this.$v.form.phone.$error) {
        return this.$t('required_phone');
      }
      return '';
    },
  },

  methods: {
    onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.formLoading = true;

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(this.form),
          url: `${process.env.VUE_APP_MAILER_URL}/send`,
        };

        axios(options)
          .then((res) => {
            console.log(res);
            this.formSended = true;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./feedback-form.scss";
</style>
