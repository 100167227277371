<i18n src="./navigation-header.json"></i18n>

<template>
  <nav class="header-navigation__container">
    <div class="header-navigation__item" @click="$emit('toggle-menu')">
      <router-link
        class="header-navigation__link"
        to="/augmented-reality"
      >
        {{ $t('augmented-reality') }}
        <i class="icon-arrow-right header-navigation__icon"></i>
      </router-link>
    </div>
    <div class="header-navigation__item" @click="$emit('toggle-menu')">
      <router-link
        class="header-navigation__link"
        to="/gamification"
      >
        {{ $t('gamification') }}
        <i class="icon-arrow-right header-navigation__icon"></i>
      </router-link>
    </div>
    <UButton
      theme="success"
      size="sm"
      easy-add="#modal"
      easy-class="modal--show"
      class="header-navigation__button-contact"
    >
      {{ $t('contact-us') }}
    </UButton>
  </nav>
</template>

<script>
import UButton from '@/components/U/Button/UButton';

export default {
  name: 'HeaderNavigation',
  components: { UButton },
  data() {
    return {

    };
  },

};
</script>

<style scoped lang="scss">
@import "./navigation-header.scss";
</style>
