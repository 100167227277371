<template>
  <component
    :is="to ? 'router-link': 'button' "
    :to="to"
    :class="[cssClass || `u-button u-button__type-${theme} u-button__size-${size}`]"
    :disabled="disabled"
  >
    <span class="u-button__text">
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'UButton',
  props: {
    cssClass: String,
    theme: {
      type: String,
      validate: (value) => ['success', 'secondary', 'empty', 'close'].includes(value),
    },
    size: {
      type: String,
      validate: (value) => ['sm', 'md', 'lg', 'mobile-414', 'rect-24'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import "./u-button.scss";
</style>
