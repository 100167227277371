<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/App/Header/AppHeader';
import AppFooter from '@/components/App/Footer/AppFooter';

export default {
  components: { AppHeader, AppFooter },
};
</script>
