<template>
  <div class="u-badge">
    <component style="opacity: 50%" :is="iconOutside"/>
    <div
      v-if="title || iconInside"
      :class="cssClass ||
      `u-badge__container u-badge__type-${type}
      u-badge__size-${size}`"
    >
      <component :is="iconInside"/>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UBadge',
  props: {
    cssClass: String,
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      validate: (value) => ['success', 'secondary', 'primary'].includes(value),
    },
    size: {
      type: String,
      validate: (value) => ['sm', 'md', 'lg', 'mobile-414'].includes(value),
    },
    iconOutside: {
      type: Object,
      default: () => {},
    },
    iconInside: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
@import "./u-badge.scss";
</style>
