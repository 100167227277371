<i18n src="./translations.json"></i18n>

<template>
  <div class="steps-banner">
    <div class="steps-banner__card-container">
      <div class="steps-banner__card-bg">
        <div class="steps-banner__card">
          <img alt="" src="@/assets/img/readytouse/image-left.png">
<!--          <img alt="" src="@/assets/img/readytouse/image-left-mobile.png">-->
          <p class="steps-banner__card-title">
            {{ $t('card-1-title') }}
          </p>
          <p class="steps-banner__card-description">
            {{ $t('card-1-text') }}
          </p>
        </div>
      </div>
      <div class="steps-banner__card-bg">
        <div class="steps-banner__card">
          <img alt="" src="@/assets/img/readytouse/image-right.png">
          <p class="steps-banner__card-title">
            {{ $t('card-2-title') }}
          </p>
          <p class="steps-banner__card-description">
            {{ $t('card-2-text') }}
          </p>
        </div>
      </div>
      <div class="steps-banner__secondary">
        <div class="steps-banner__secondary-item">
          <component :is="iconChat"/>
          <p class="steps-banner__secondary-text">
            {{ $t('contract_signing') }}
          </p>
        </div>
        <div class="steps-banner__secondary-hr">
          <img src="@/assets/img/readytouse/line.png" alt="" class="hr-line">
          <img src="@/assets/img/readytouse/line-mob.png" alt="" class="hr-line-mob">
        </div>
        <div class="steps-banner__secondary-item">
          <component :is="iconRocket"/>
          <p class="steps-banner__secondary-text">
            {{ $t('launch') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ReadyUseIconsDesktop, ReadyUseIconsMobile } from '@/assets/icons/readytouse/icon-pack';

export default {
  name: 'StepsBanner',
  data() {
    return {
      iconRocket: ReadyUseIconsDesktop.Rocket,
      iconRocketMobile: ReadyUseIconsMobile.RocketMobile,
      iconChat: ReadyUseIconsDesktop.Chat,
      iconChatMobile: ReadyUseIconsMobile.ChatMobile,
    };
  },
};
</script>

<style scoped lang="scss">
@import "./steps-banner.scss";
</style>
