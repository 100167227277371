<i18n src="./translations.json"></i18n>

<template>
  <footer class="footer container">
    <p class="horizontal-line"/>
    <div class="footer__mail">
      <component :style="{ opacity: 0.6 }" :is="mailIcon"/>
      <a
        class="footer__mail-title"
        href="mailto:info@crespo.app"
      >
        info@crespo.app
      </a>
    </div>
    <p class="horizontal-line"/>
    <div class="footer__legal">
      <p class="footer__legal-copyright"
      >
        {{ currentYear }} DC Sport Soft LTD. {{ $t('copyright') }}
      </p>
      <div class="footer__legal-links">
        <router-link
          :key="i"
          v-for="(link, i) in links"
          :to="link.url"
        >
          <UButton
            size="sm"
            theme="empty"
          >
            {{ link.title }}
          </UButton>
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import IconsPack from '@/assets/icons/general/icons-pack';
import UButton from '@/components/U/Button/UButton';

export default {
  name: 'FooterContainer',
  components: { UButton },
  data() {
    return {
      mailIcon: IconsPack.Mail02,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },

    links() {
      return [
        {
          title: this.$t('policy'),
          url: '/policy',
        },
        {
          title: this.$t('rights'),
          url: '/terms-and-conditions',
        },
        {
          title: this.$t('cookies'),
          url: '/cookies-policy',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import "./footer.scss";
</style>
