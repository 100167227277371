const phoneMasks = [
  { code: 'AC', mask: '+247-####' },
  { code: 'AD', mask: '+376-###-###' },
  { code: 'AE', mask: '+971-#-###-####' },
  { code: 'AF', mask: '+93-##-###-####' },
  { code: 'AG', mask: '+1(268)###-####' },
  { code: 'AI', mask: '+1(264)###-####' },
  { code: 'AL', mask: '+355(###)###-###' },
  { code: 'AM', mask: '+374-##-###-###' },
  { code: 'AN', mask: '+599-9###-####' },
  { code: 'AO', mask: '+244(###)###-###' },
  { code: 'AQ', mask: '+672-1##-###' },
  { code: 'AR', mask: '+54(###)###-####' },
  { code: 'AS', mask: '+1(684)###-####' },
  { code: 'AT', mask: '+43(###)###-####' },
  { code: 'AU', mask: '+61-#-####-####' },
  { code: 'AW', mask: '+297-###-####' },
  { code: 'AZ', mask: '+994-##-###-##-##' },
  { code: 'BA', mask: '+387-##-####' },
  { code: 'BB', mask: '+1(246)###-####' },
  { code: 'BD', mask: '+880-##-###-###' },
  { code: 'BE', mask: '+32(###)###-###' },
  { code: 'BF', mask: '+226-##-##-####' },
  { code: 'BG', mask: '+359(###)###-###' },
  { code: 'BH', mask: '+973-####-####' },
  { code: 'BI', mask: '+257-##-##-####' },
  { code: 'BJ', mask: '+229-##-##-####' },
  { code: 'BM', mask: '+1(441)###-####' },
  { code: 'BN', mask: '+673-###-####' },
  { code: 'BO', mask: '+591-#-###-####' },
  { code: 'BR', mask: '+55(##)9####-####' },
  { code: 'BS', mask: '+1(242)###-####' },
  { code: 'BT', mask: '+975-#-###-###' },
  { code: 'BW', mask: '+267-##-###-###' },
  { code: 'BY', mask: '+375(##)###-##-##' },
  { code: 'BZ', mask: '+501-###-####' },
  { code: 'CA', mask: '+1(###)###-####' },
  { code: 'CD', mask: '+243(###)###-###' },
  { code: 'CF', mask: '+236-##-##-####' },
  { code: 'CG', mask: '+242-##-###-####' },
  { code: 'CH', mask: '+41-##-###-####' },
  { code: 'CI', mask: '+225-##-###-###' },
  { code: 'CK', mask: '+682-##-###' },
  { code: 'CL', mask: '+56-#-####-####' },
  { code: 'CM', mask: '+237-####-####' },
  { code: 'CN', mask: '+86-##-#####-#####' },
  { code: 'CO', mask: '+57(###)###-####' },
  { code: 'CR', mask: '+506-####-####' },
  { code: 'CU', mask: '+53-#-###-####' },
  { code: 'CV', mask: '+238(###)##-##' },
  { code: 'CW', mask: '+599-###-####' },
  { code: 'CY', mask: '+357-##-###-###' },
  { code: 'CZ', mask: '+420(###)###-###' },
  { code: 'DE', mask: '+49-###-###' },
  { code: 'DJ', mask: '+253-##-##-##-##' },
  { code: 'DK', mask: '+45-##-##-##-##' },
  { code: 'DM', mask: '+1(767)###-####' },
  { code: 'DO', mask: '+1(849)###-####' },
  { code: 'DZ', mask: '+213-##-###-####' },
  { code: 'EC', mask: '+593-#-###-####' },
  { code: 'EE', mask: '+372-###-####' },
  { code: 'EG', mask: '+20(###)###-####' },
  { code: 'ER', mask: '+291-#-###-###' },
  { code: 'ES', mask: '+34(###)###-###' },
  { code: 'ET', mask: '+251-##-###-####' },
  { code: 'FI', mask: '+358(###)###-##-##' },
  { code: 'FJ', mask: '+679-##-#####' },
  { code: 'FK', mask: '+500-#####' },
  { code: 'FM', mask: '+691-###-####' },
  { code: 'FO', mask: '+298-###-###' },
  { code: 'FR', mask: '+590(###)###-###' },
  { code: 'GA', mask: '+241-#-##-##-##' },
  { code: 'GD', mask: '+1(473)###-####' },
  { code: 'GE', mask: '+995(###)###-###' },
  { code: 'GF', mask: '+594-#####-####' },
  { code: 'GH', mask: '+233(###)###-###' },
  { code: 'GI', mask: '+350-###-#####' },
  { code: 'GL', mask: '+299-##-##-##' },
  { code: 'GM', mask: '+220(###)##-##' },
  { code: 'GN', mask: '+224-##-###-###' },
  { code: 'GQ', mask: '+240-##-###-####' },
  { code: 'GR', mask: '+30(###)###-####' },
  { code: 'GT', mask: '+502-#-###-####' },
  { code: 'GU', mask: '+1(671)###-####' },
  { code: 'GW', mask: '+245-#-######' },
  { code: 'GY', mask: '+592-###-####' },
  { code: 'HK', mask: '+852-####-####' },
  { code: 'HN', mask: '+504-####-####' },
  { code: 'HR', mask: '+385-##-###-###' },
  { code: 'HT', mask: '+509-##-##-####' },
  { code: 'HU', mask: '+36(###)###-###' },
  { code: 'ID', mask: '+62(8##)###-##-###' },
  { code: 'IE', mask: '+353(###)###-###' },
  { code: 'IL', mask: '+972-#-###-####' },
  { code: 'IN', mask: '+91(####)###-###' },
  { code: 'IO', mask: '+246-###-####' },
  { code: 'IQ', mask: '+964(###)###-####' },
  { code: 'IR', mask: '+98(###)###-####' },
  { code: 'IS', mask: '+354-###-####' },
  { code: 'IT', mask: '+39(###)####-###' },
  { code: 'JM', mask: '+1(876)###-####' },
  { code: 'JO', mask: '+962-#-####-####' },
  { code: 'JP', mask: '+81(###)###-###' },
  { code: 'KE', mask: '+254-###-######' },
  { code: 'KG', mask: '+996(###)###-###' },
  { code: 'KH', mask: '+855-##-###-###' },
  { code: 'KI', mask: '+686-##-###' },
  { code: 'KM', mask: '+269-##-#####' },
  { code: 'KN', mask: '+1(869)###-####' },
  { code: 'KP', mask: '+850-####-#############' },
  { code: 'KR', mask: '+82-##-###-####' },
  { code: 'KW', mask: '+965-####-####' },
  { code: 'KY', mask: '+1(345)###-####' },
  { code: 'KZ', mask: '+7(7##)###-##-##' },
  { code: 'LA', mask: '+856-##-###-###' },
  { code: 'LB', mask: '+961-#-###-###' },
  { code: 'LC', mask: '+1(758)###-####' },
  { code: 'LI', mask: '+423(###)###-####' },
  { code: 'LK', mask: '+94-##-###-####' },
  { code: 'LR', mask: '+231-##-###-###' },
  { code: 'LS', mask: '+266-#-###-####' },
  { code: 'LT', mask: '+370(###)##-###' },
  { code: 'LU', mask: '+352(###)###-###' },
  { code: 'LV', mask: '+371-##-###-###' },
  { code: 'LY', mask: '+218-21-###-####' },
  { code: 'MA', mask: '+212-##-####-###' },
  { code: 'MC', mask: '+377-##-###-###' },
  { code: 'MD', mask: '+373-####-####' },
  { code: 'ME', mask: '+382-##-###-###' },
  { code: 'MG', mask: '+261-##-##-#####' },
  { code: 'MH', mask: '+692-###-####' },
  { code: 'MK', mask: '+389-##-###-###' },
  { code: 'ML', mask: '+223-##-##-####' },
  { code: 'MM', mask: '+95-###-###' },
  { code: 'MN', mask: '+976-##-##-####' },
  { code: 'MO', mask: '+853-####-####' },
  { code: 'MP', mask: '+1(670)###-####' },
  { code: 'MQ', mask: '+596(###)##-##-##' },
  { code: 'MR', mask: '+222-##-##-####' },
  { code: 'MS', mask: '+1(664)###-####' },
  { code: 'MT', mask: '+356-####-####' },
  { code: 'MU', mask: '+230-###-####' },
  { code: 'MV', mask: '+960-###-####' },
  { code: 'MW', mask: '+265-#-####-####' },
  { code: 'MX', mask: '+52-##-##-####' },
  { code: 'MY', mask: '+60-#-###-###' },
  { code: 'MZ', mask: '+258-##-###-###' },
  { code: 'NA', mask: '+264-##-###-####' },
  { code: 'NC', mask: '+687-##-####' },
  { code: 'NE', mask: '+227-##-##-####' },
  { code: 'NF', mask: '+672-3##-###' },
  { code: 'NG', mask: '+234(###)###-####' },
  { code: 'NI', mask: '+505-####-####' },
  { code: 'NL', mask: '+31-##-###-####' },
  { code: 'NO', mask: '+47(###)##-###' },
  { code: 'NP', mask: '+977-##-###-###' },
  { code: 'NR', mask: '+674-###-####' },
  { code: 'NU', mask: '+683-####' },
  { code: 'NZ', mask: '+64(###)###-####' },
  { code: 'OM', mask: '+968-##-###-###' },
  { code: 'PA', mask: '+507-###-####' },
  { code: 'PE', mask: '+51(###)###-###' },
  { code: 'PF', mask: '+689-##-##-##' },
  { code: 'PG', mask: '+675(###)##-###' },
  { code: 'PH', mask: '+63(###)###-####' },
  { code: 'PK', mask: '+92(###)###-####' },
  { code: 'PL', mask: '+48(###)###-###' },
  { code: 'PS', mask: '+970-##-###-####' },
  { code: 'PT', mask: '+351-##-###-####' },
  { code: 'PW', mask: '+680-###-####' },
  { code: 'PY', mask: '+595(###)###-###' },
  { code: 'QA', mask: '+974-####-####' },
  { code: 'RE', mask: '+262-#####-####' },
  { code: 'RO', mask: '+40-##-###-####' },
  { code: 'RS', mask: '+381-##-###-####' },
  { code: 'RU', mask: '+7(###)###-##-##' },
  { code: 'RW', mask: '+250(###)###-###' },
  { code: 'SA', mask: '+966-#-###-####' },
  { code: 'SB', mask: '+677-#####' },
  { code: 'SC', mask: '+248-#-###-###' },
  { code: 'SD', mask: '+249-##-###-####' },
  { code: 'SE', mask: '+46-##-###-####' },
  { code: 'SG', mask: '+65-####-####' },
  { code: 'SH', mask: '+290-####' },
  { code: 'SI', mask: '+386-##-###-###' },
  { code: 'SK', mask: '+421(###)###-###' },
  { code: 'SL', mask: '+232-##-######' },
  { code: 'SM', mask: '+378-####-######' },
  { code: 'SN', mask: '+221-##-###-####' },
  { code: 'SO', mask: '+252-#-###-###' },
  { code: 'SR', mask: '+597-###-###' },
  { code: 'SS', mask: '+211-##-###-####' },
  { code: 'ST', mask: '+239-##-#####' },
  { code: 'SV', mask: '+503-##-##-####' },
  { code: 'SX', mask: '+1(721)###-####' },
  { code: 'SY', mask: '+963-##-####-###' },
  { code: 'SZ', mask: '+268-##-##-####' },
  { code: 'TC', mask: '+1(649)###-####' },
  { code: 'TD', mask: '+235-##-##-##-##' },
  { code: 'TG', mask: '+228-##-###-###' },
  { code: 'TH', mask: '+66-##-###-###' },
  { code: 'TJ', mask: '+992-##-###-####' },
  { code: 'TK', mask: '+690-####' },
  { code: 'TL', mask: '+670-78#-#####' },
  { code: 'TM', mask: '+993-#-###-####' },
  { code: 'TN', mask: '+216-##-###-###' },
  { code: 'TO', mask: '+676-#####' },
  { code: 'TR', mask: '+90(###)###-####' },
  { code: 'TT', mask: '+1(868)###-####' },
  { code: 'TV', mask: '+688-2####' },
  { code: 'TW', mask: '+886-####-####' },
  { code: 'TZ', mask: '+255-##-###-####' },
  { code: 'UA', mask: '+380(##)###-##-##' },
  { code: 'UG', mask: '+256(###)###-###' },
  { code: 'UK', mask: '+44-##-####-####' },
  { code: 'US', mask: '+1(###)###-####' },
  { code: 'UY', mask: '+598-#-###-##-##' },
  { code: 'UZ', mask: '+998-##-###-####' },
  { code: 'VA', mask: '+39-6-698-#####' },
  { code: 'VC', mask: '+1(784)###-####' },
  { code: 'VE', mask: '+58(###)###-####' },
  { code: 'VG', mask: '+1(284)###-####' },
  { code: 'VI', mask: '+1(340)###-####' },
  { code: 'VN', mask: '+84(###)####-###' },
  { code: 'VU', mask: '+678-#####' },
  { code: 'WF', mask: '+681-##-####' },
  { code: 'WS', mask: '+685-##-####' },
  { code: 'YE', mask: '+967-##-###-###' },
  { code: 'ZA', mask: '+27-##-###-####' },
  { code: 'ZM', mask: '+260-##-###-####' },
  { code: 'ZW', mask: '+263-#-######' },
];

export default phoneMasks;
