import { render, staticRenderFns } from "./AugmentedRealityView.vue?vue&type=template&id=6b51c953&"
import script from "./AugmentedRealityView.vue?vue&type=script&lang=js&"
export * from "./AugmentedRealityView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../i18n/augmented-realty.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FAugmentedRealityView.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports