import Cookies from 'js-cookie';

const SUPPORT_LOCALES = [
  'en',
  'ru',
];

function detectLocale() {
  const supportLocales = ['en', 'ru'];
  const language = window.navigator.language.substring(0, 2);
  const savedLocale = Cookies.get('locale');

  if (savedLocale !== null && supportLocales.indexOf(savedLocale) !== -1) {
    return savedLocale;
  }

  if (supportLocales.indexOf(language) === -1) {
    return supportLocales[0];
  }

  return language;
}

export {
  SUPPORT_LOCALES,
  detectLocale,
};
