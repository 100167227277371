<template>
  <div class="tabs">
    <div class="tabs__header" :style="`grid-template-columns: repeat(${ tabs.length }, 1fr);`">
      <div class="tabs__header--border"/>
      <button
        v-for="tab in tabs"
        :key="tab.id"
        class="tabs__item tab"
        :class="{'tab--selected': activeTab === tab.title}"
        @click="setActiveTab(tab)"
        ref="buttonTab"
      >
        <i :class="`icon-${tab.icon} tab__icon`"></i>
        <span class="tab__label">
          <span class="tab__label-text">{{ tab.label }}</span>
          <span class="tab__label-text--mobile">{{ tab.labelMini }}</span>
        </span>
      </button>
      <span class="tab__indicator" :style="indicatorStyles"></span>
    </div>
    <!-- /.tabs__header -->

    <div class="tabs__content">
      <transition-group name="tab" tag="div">
        <div
          v-for="tab in tabs"
          :key="tab.title"
          v-show="activeTab === tab.title"
          class="tabs__content-item"
        >
          <slot :name="tab.title"/>
        </div>
        <!-- /.tabs__contents-item -->
      </transition-group>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UTabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: null,
      indicatorStyles: {
        left: 0,
        width: `${(100 / this.tabs.length)}%`,
      },
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab.title;
      this.activeTab = tab.title;
      this.indicatorStyles.left = `${this.tabs.indexOf(tab) * (100 / this.tabs.length)}%`;
    },
  },
  mounted() {
    this.setActiveTab(this.tabs[0]);
  },
};
</script>

<style scoped lang="scss">
@import "./u-tabs.scss";
</style>
