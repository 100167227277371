<i18n src="./translations.json"></i18n>

<template>
  <section class="ready-to-use">
   <div class="container ready-to-use__content">
    <img src="@/assets/img/readytouse/bg-image.png" alt="" class="ready-to-use__background">
    <img src="@/assets/img/readytouse/bg-image-mobile.png" alt=""
         class="ready-to-use__background--mobile">
     <div class="ready-to-use__heading">
       <h2 class="section__title">
        {{ $t('title') }}
       </h2>

       <span class="section__subtitle">
          {{ $t('subtitle') }}
        </span>
      </div>
      <div class="container">
        <StepsBanner/>
      </div>
      <!-- /.container -->
      <div class="container">
        <TimeLine/>
      </div>
      <!-- /.container -->
    </div>
    <!-- /.container -->
  </section>
</template>

<script>
import StepsBanner from '@/components/StepsBanner/StepsBanner';
import TimeLine from '@/components/Timeline/TimeLine';

export default {
  name: 'ReadyToUse',
  components: { StepsBanner, TimeLine },
};
</script>

<style scoped lang="scss">
@import "./ready-to-use.scss";
</style>
