import Club from './icons/club-414.svg';
import Dinamo from './icons/dinamo-414.svg';
import Right from './icons/right-414.svg';

const IconsPackMobile = Object.freeze({
  Club,
  Dinamo,
  Right,
});

export default IconsPackMobile;
