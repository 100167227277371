<template>
  <div
    :class="[
      `u-card u-card__size-${size}`,
      { 'u-card__overlay': uCard.overlay, }
      ]"
  >
    <img v-if="background"  :src="background" alt="" class="u-card__background">
    <div class="u-card__body">
      <UBadge
        :type="uBadge.type"
        :size="uBadge.size"
        :title="uBadge.title"
        :icon-inside="uBadge.iconInside"
        :icon-outside="uBadge.iconOutside"
      />
      <div class="u-card__text-container">
        <div :class="`u-card__text u-card__text-${size}`">
          <p :class="`u-card__title u-card__title-${size}`">
            {{ title }}
          </p>
          <p class="u-card__description">{{ description }}</p>
        </div>
        <UButton
          v-if="uButton"
          :size="'sm'"
          :theme="uButton.type"
          @click="onClick"
          :to="uCard.url"
          class="u-card__button"
        >
          {{ uButton.title }}
          <component style="opacity: 60%" :is="uButton.icon"/>
        </UButton>
      </div>
    </div>
  </div>
</template>

<script>
import UButton from '@/components/U/Button/UButton';
import UBadge from '@/components/U/Badge/UBadge';

export default {
  name: 'UCard',
  components: { UButton, UBadge },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    background() {
      return this.uCard.background;
    },
    size() {
      return this.uCard.size;
    },
    title() {
      return this.uCard.title;
    },
    description() {
      return this.uCard.description;
    },
    uCard() {
      const { uCard } = this.content;
      return uCard;
    },
    // Параметры не относящиеся к компоненту UCard
    uBadge() {
      const { uBadge } = this.content;
      return uBadge;
    },
    uButton() {
      const { uButton } = this.content;
      return uButton;
    },
  },
  methods: {
    onClick() {
      const { url } = this.uCard;
      this.$emit('on-click', url);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./u-card.scss";
</style>
