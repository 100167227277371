<i18n src="./translations.json"></i18n>

<template>
  <div class="features">
    <div class="features__heading">
      <h2 class="section__title">
        {{ $t('title') }}
      </h2>
      <p class="features__heading-text">
        {{ $t('subtitle') }}
      </p>
    </div>
    <div class="features__grid">
      <div class="features__grid-item">
        <div>
          <p class="features__grid-item-title_more">
            {{ $t('card-1-title') }}
          </p>
          <p class="features__grid-item-description">
            {{ $t('card-1-text') }}
          </p>
        </div>
        <img alt="" :src="$t('matches')">
      </div>
      <div class="features__grid-item">
        <div>
          <p class="features__grid-item-title">
            {{ $t('card-2-title') }}
          </p>
          <p class="features__grid-item-description">
            {{ $t('card-2-text') }}
          </p>
        </div>
        <img alt="" :src="$t('news')">
      </div>
      <div class="features__grid-item">
        <div>
          <p class="features__grid-item-title">
            {{ $t('card-3-title') }}
          </p>
          <p class="features__grid-item-description">
            {{ $t('card-3-text') }}
          </p>
        </div>
        <img alt="" :src="$t('information')">
      </div>
      <div class="features__grid-item"
      >
        <div>
          <p class="features__grid-item-title">
            {{ $t('card-4-title') }}
          </p>
        </div>
        <img alt="" :src="$t('champions')">
      </div>
      <div class="features__grid-item">
        <div>
          <p class="features__grid-item-title">
            {{ $t('card-5-title') }}
          </p>
          <p class="features__grid-item-description">
            {{ $t('card-5-text') }}
          </p>
        </div>
        <img alt="" :src="$t('videos')">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesContainer',
};
</script>

<style scoped lang="scss">
@import "./features.scss";
</style>
