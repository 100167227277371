var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[`u-input u-input__type-${_vm.type}`],attrs:{"for":"input"}},[_c('span',{staticClass:"u-input__label"},[_vm._v(_vm._s(_vm.label))]),(_vm.type === 'textarea')?_c('textarea',{class:[
      'u-input__field' ,
      { 'u-input__field--error': _vm.message }
    ],attrs:{"rows":_vm.rows,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"name":"text"},domProps:{"value":_vm.newValue},on:{"keyup":_vm.updateValue}}):(_vm.type==='tel')?_c('input',{directives:[{name:"maska",rawName:"v-maska:[maskOptions]",arg:_vm.maskOptions}],class:[
      'u-input__field',
      { 'u-input__field--error': _vm.message }
    ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":_vm.newValue},on:{"keyup":_vm.updateValue}}):_c('input',{class:[
      'u-input__field',
      { 'u-input__field--error': _vm.message }
    ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":_vm.newValue},on:{"keyup":_vm.updateValue}}),_c('span',{staticClass:"u-input__message"},[_vm._v(_vm._s(_vm.message))])])
}
var staticRenderFns = []

export { render, staticRenderFns }