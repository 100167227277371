import Club from './icons/club.svg';
import Dinamo from './icons/dinamo.svg';
import Right from './icons/right.svg';

const IconsPackDesktop = Object.freeze({
  Club,
  Dinamo,
  Right,
});

export default IconsPackDesktop;
