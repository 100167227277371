<i18n src="./translations.json"></i18n>

<template>
  <div class="tech-solution flex column items-center gap-144">
    <div class="flex column items-center gap-104">
      <div class="flex column items-center gap-24">
        <h2 class="section__title" v-html="$t('title')" />
      </div>
      <div class="tech-solution__grid">
        <div class="tech-solution__grid-item"
          :key="i"
          v-for="(cont, i) in content"
        >
          <div class="tech-solution__grid-header"
          >
            <p class="tech-solution__title">
              {{ cont.title }}
            </p>
            <p class="tech-solution__description">
              {{ cont.description }}
            </p>
          </div>
          <img :src="cont.image" alt="">
        </div>
      </div>
      <!-- /.tech-solution__content -->
    </div>
    <Integrations
      :integrations="integrations"
    />
  </div>
</template>

<script>
import Integrations from '@/components/Integrations/Integrations';
import {
  IntegrationsIconsDesktop,
  IntegrationsIconsMobile,
} from '@/assets/icons/integrations/icons-pack';

export default {
  name: 'TechSolutionsContainer',
  components: { Integrations },
  data() {
    return {
      integrations: [
        {
          url: 'http://facebook.com/',
          icon: IntegrationsIconsDesktop.Facebook,
          iconMini: IntegrationsIconsMobile.Facebook,
        },
        {
          url: 'http://analytics.google.com/',
          icon: IntegrationsIconsDesktop.GoogleAnalytics,
          iconMini: IntegrationsIconsMobile.GoogleAnalytics,
        },
        {
          url: 'https://appmetrica.yandex.com/',
          icon: IntegrationsIconsDesktop.AppMetrica,
          iconMini: IntegrationsIconsMobile.AppMetrica,
        },
        {
          url: 'http://www.appsflyer.com/',
          icon: IntegrationsIconsDesktop.AppsFlyer,
          iconMini: IntegrationsIconsMobile.AppsFlyer,
        },
        {
          url: 'https://adfox.yandex.ru/',
          icon: IntegrationsIconsDesktop.Adfox,
          iconMini: IntegrationsIconsMobile.Adfox,
        },
        {
          url: 'https://dynamics.microsoft.com/',
          icon: IntegrationsIconsDesktop.MicrosoftDynamicsCrm,
          iconMini: IntegrationsIconsMobile.MicrosoftDynamicsCrm,
        },
        {
          url: 'https://www.sap.com/products/crm.html',
          icon: IntegrationsIconsDesktop.SAPCrm,
          iconMini: IntegrationsIconsMobile.SAPCrm,
        },
        {
          url: 'https://instatsport.com/',
          icon: IntegrationsIconsDesktop.InStat,
          iconMini: IntegrationsIconsMobile.InStat,
        },
        {
          url: 'https://manzanagroup.ru/',
          icon: IntegrationsIconsDesktop.Manzana,
          iconMini: IntegrationsIconsMobile.Manzana,
        },
        {
          url: 'http://www.optasports.com/',
          icon: IntegrationsIconsDesktop.Opta,
          iconMini: IntegrationsIconsMobile.Opta,
        },
        {
          url: 'https://www.sportradar.com/',
          icon: IntegrationsIconsDesktop.SportRadar,
          iconMini: IntegrationsIconsMobile.SportRadar,
        },
        {
          url: 'http://www.wyscout.com/',
          icon: IntegrationsIconsDesktop.WyScout,
          iconMini: IntegrationsIconsMobile.WyScout,
        },
      ],
    };
  },
  computed: {
    content() {
      return [
        {
          title: this.$t('card-1-title'),
          description: this.$t('card-1-text'),
          image: this.$t('image-ts-1'),
          insideLayout: 'left-right',
        },
        {
          title: this.$t('card-2-title'),
          description: this.$t('card-2-text'),
          image: this.$t('image-ts-2'),
          imageMini: 'ts-mobile-2',
          insideLayout: 'top-bottom gap-40',
        },
        {
          title: this.$t('card-3-title'),
          description: this.$t('card-3-text'),
          image: this.$t('image-ts-3'),
          imageMini: 'ts-mobile-3',
          insideLayout: 'top-bottom gap-0',
        },
        {
          title: this.$t('card-4-title'),
          description: this.$t('card-4-text'),
          image: this.$t('image-ts-4'),
          imageMini: 'ts-mobile-4',
          insideLayout: 'left-right',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import "./tech-solution.scss";
</style>
