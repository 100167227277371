<i18n src="../i18n/terms-and-conditions.json"></i18n>

<template>
  <section class="section policy ">
    <div class="container">
      <h1 class="policy__title"> {{ $t('title') }} </h1>
      <div v-html="$t('content')"></div>
    </div>
    <!-- /.container -->
    <Modal/>
  </section>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';

export default {
  name: 'TermsAndConditionsView',
  components: { Modal },
  data() {
    return {};
  },
};
</script>
