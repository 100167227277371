import ArrowRight from './icons/arrow-right.svg';
import CurrencyDollar from './icons/currency-dollar.svg';
import PuzzlePiece01 from './icons/puzzle-piece-01.svg';
import Puzzle from './icons/puzzle.svg';
import ReceiptTax from './icons/receipt-tax.svg';
import Support from './icons/support.svg';

const MoreFeaturesIconsDesktop = Object.freeze({
  ArrowRight,
  CurrencyDollar,
  PuzzlePiece01,
  Puzzle,
  ReceiptTax,
  Support,
});

export default MoreFeaturesIconsDesktop;
