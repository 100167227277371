import IconsPackMobile from '@/assets/icons/footercta/mobile/icons-pack-mobile';
import IconsPackDesktop from '@/assets/icons/footercta/desktop/icons-pack-desktop';

const FooterCtaIconsDesktop = IconsPackDesktop;

const FooterCtaIconsMobile = IconsPackMobile;

export {
  FooterCtaIconsDesktop,
  FooterCtaIconsMobile,
};
