import Dinamo from './icons/Dinamo.svg';
import Facebook from './icons/Facebook.svg';
import Fans from './icons/Fans.svg';
import Partners from './icons/Partners.svg';
import Team from './icons/Team.svg';
import Telegram from './icons/Telegram.svg';
import Twitter from './icons/Twitter.svg';
import AcademicCap from './icons/academic-cap.svg';
import ArrowRight from './icons/arrow-right.svg';
import BadgeCheck from './icons/badge-check.svg';
import ChartBar from './icons/chart-bar.svg';
import ChatAlt2 from './icons/chat-alt-2.svg';
import CheckCircleBroken from './icons/check-circle-broken.svg';
import Collection from './icons/collection.svg';
import CurrencyDollar from './icons/currency-dollar.svg';
import DeviceMobile from './icons/device-mobile.svg';
import Fire from './icons/fire.svg';
import GameController from './icons/game-controller.svg';
import Globe from './icons/globe.svg';
import LightBulb from './icons/light-bulb.svg';
import Mail02 from './icons/mail-02.svg';
import Mask from './icons/mask.svg';
import Menu01 from './icons/menu-01.svg';
import MessageQuestionCircle from './icons/message-question-circle.svg';
import Pool8Ball from './icons/pool-8-ball.svg';
import PuzzlePiece01 from './icons/puzzle-piece-01.svg';
import Puzzle from './icons/puzzle.svg';
import QuestionMarkCircle from './icons/question-mark-circle.svg';
import ReceiptTax from './icons/receipt-tax.svg';
import Rocket from './icons/rocket.svg';
import Scale from './icons/scale.svg';
import Speakerphone from './icons/speakerphone.svg';
import SportsSoccer from './icons/sports-soccer.svg';
import StatusOnline from './icons/status-online.svg';
import Support from './icons/support.svg';
import TShirt from './icons/t-shirt.svg';
import Translate02 from './icons/translate-02.svg';
import TrendUp01 from './icons/trend-up-01.svg';
import Trophy from './icons/trophy.svg';
import UserGroup from './icons/user-group.svg';
import ViewInAr from './icons/view-in-ar.svg';
import XClose07414 from './icons/x-close-07-414.svg';
import XClose07 from './icons/x-close-07.svg';
import XClose from './icons/x-close.svg';

const IconsPack = Object.freeze({
  Dinamo,
  Facebook,
  Fans,
  Partners,
  Team,
  Telegram,
  Twitter,
  AcademicCap,
  ArrowRight,
  BadgeCheck,
  ChartBar,
  ChatAlt2,
  CheckCircleBroken,
  Collection,
  CurrencyDollar,
  DeviceMobile,
  Fire,
  GameController,
  Globe,
  LightBulb,
  Mail02,
  Mask,
  Menu01,
  MessageQuestionCircle,
  Pool8Ball,
  PuzzlePiece01,
  Puzzle,
  QuestionMarkCircle,
  ReceiptTax,
  Rocket,
  Scale,
  Speakerphone,
  SportsSoccer,
  StatusOnline,
  Support,
  TShirt,
  Translate02,
  TrendUp01,
  Trophy,
  UserGroup,
  ViewInAr,
  XClose07414,
  XClose07,
  XClose,
});

export default IconsPack;
