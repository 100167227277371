import IconsPackDesktop from '@/assets/icons/readytouse/desktop/icons-pack-desktop';
import IconsPackMobile from '@/assets/icons/readytouse/mobile/icons-pack-mobile';

const ReadyUseIconsDesktop = IconsPackDesktop;

const ReadyUseIconsMobile = IconsPackMobile;

export {
  ReadyUseIconsDesktop,
  ReadyUseIconsMobile,
};
