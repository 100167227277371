<template>
  <div
    class="toggler-lang__container"
    @click="toggleLang"
    @keyup="toggleLang"
  >
    <IconLang />
    <p class="toggler-lang__container-text">
      {{ $i18n.locale.toUpperCase() }}
    </p>
  </div>
</template>

<script>
import IconLang from '@/assets/icons/lang.svg';
import { SUPPORT_LOCALES, detectLocale } from '@/i18n';
import Cookies from 'js-cookie';

export default {
  name: 'TogglerLang',
  components: { IconLang },
  data() {
    return {
      locales: SUPPORT_LOCALES,
      locale: null,
    };
  },

  watch: {
    locale() {
      this.$i18n.locale = this.locale;
    },
  },

  mounted() {
    this.locale = detectLocale();
  },

  methods: {
    toggleLang() {
      this.locale = this.locales.filter((item) => item !== this.locale).pop();
      Cookies.set('locale', this.locale, { expires: 365 });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./toggler-lang";
</style>
