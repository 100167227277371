import IconsPackMobile from '@/assets/icons/integrations/mobile/icons-pack-mobile';
import IconsPackDesktop from '@/assets/icons/integrations/desktop/icons-pack-desktop';

const IntegrationsIconsDesktop = IconsPackDesktop;

const IntegrationsIconsMobile = IconsPackMobile;

export {
  IntegrationsIconsDesktop,
  IntegrationsIconsMobile,
};
