<i18n src="../i18n/augmented-realty.json"></i18n>

<template>
  <BasePage>
    <template #caption>
      <UBadge
        type="primary"
        size="sm"
        :title="uBadge.title"
        :icon-inside="uBadge.iconInside"
        style="display: inline-block;"
      />
    </template>

    <template #h1>{{ $t('title') }}</template>

    <template #aside>
      <div class="cols-2 mb-16">
        <CardV2>
          <template #icon><i class="icon-view-in-ar"></i></template>
          <template #title>{{ $t('ar') }}</template>
          <template #text>{{ $t('ar-text') }}</template>
        </CardV2>

        <CardV2>
          <template #icon><i class="icon-puzzle text-purple"></i></template>
          <template #title>{{ $t('additional_module2') }}</template>
          <template #text>{{ $t('additional_module2-text') }}</template>
        </CardV2>
      </div>
    </template>

    <div
      v-for="(content, i) in contents"
      :key="'content_'+i"
    >
      <SecCard
        v-for="(sec, l) in content"
        :key="'sec_'+l"
        :img="sec.img"
        :reverse="sec.reverse"
      >
        <template #caption>
          <span>
            <i :class="[sec.icon]"></i>
            {{ sec.caption }}
          </span>
        </template>
        <template #title>{{ sec.title }}</template>
        <template #text>{{ sec.text }}</template>
      </SecCard>
    </div>

    <template #preend>
      <p class="horizontal-line" />
      <FooterCta />
      <Modal />
    </template>
  </BasePage>
</template>

<script>
import UBadge from '@/components/U/Badge/UBadge.vue';
import MoreFeaturesIconsDesktop from '@/assets/icons/morefeatures/desktop/icons-pack-desktop';
import MoreFeaturesIconsMobile from '@/assets/icons/morefeatures/mobile/icons-pack-mobile';
import SecCard from '@/components/SecCard/SecCard.vue';
import BasePage from '@/components/BasePage/BasePage.vue';
import FooterCta from '@/components/FooterCTA/FooterCta.vue';
import Modal from '@/components/Modal/Modal.vue';
import CardV2 from '@/components/U/CardV2/CardV2.vue';

export default {
  name: 'AugmentedRealityView',

  components: {
    BasePage,
    UBadge,
    CardV2,
    SecCard,
    FooterCta,
    Modal,
  },

  computed: {
    contents() {
      return [
        [
          {
            caption: this.$t('card-1-caption'),
            title: this.$t('card-1-title'),
            text: this.$t('card-1-text'),
            img: '/img/ar/Image-min.png',
            icon: 'icon-globe',
          },
          {
            caption: this.$t('card-2-caption'),
            title: this.$t('card-2-title'),
            text: this.$t('card-2-text'),
            img: '/img/ar/Image-1-min.png',
            reverse: true,
            icon: 'icon-user-group',
          },
          {
            caption: this.$t('card-3-caption'),
            title: this.$t('card-3-title'),
            text: this.$t('card-3-text'),
            img: '/img/ar/Image-2-min.png',
            icon: 'icon-mask',
          },
        ],
        [
          {
            caption: this.$t('card-4-caption'),
            title: this.$t('card-4-title'),
            text: this.$t('card-4-text'),
            img: '/img/ar/Image-3-min.png',
            reverse: true,
            icon: 'icon-trophy',
          },
          {
            caption: this.$t('card-5-caption'),
            title: this.$t('card-5-title'),
            text: this.$t('card-5-text'),
            img: '/img/ar/Image-4-min.png',
            icon: 'icon-t-shirt',
          },
          {
            caption: this.$t('card-6-caption'),
            title: this.$t('card-6-title'),
            text: this.$t('card-6-text'),
            img: '/img/ar/Image-5-min.png',
            reverse: true,
            icon: 'icon-academic-cap',
          },
        ],
        [
          {
            caption: this.$t('card-7-caption'),
            title: this.$t('card-7-title'),
            text: this.$t('card-7-text'),
            img: '/img/ar/Image-6-min.png',
            icon: 'icon-Team',
          },
          {
            caption: this.$t('card-8-caption'),
            title: this.$t('card-8-title'),
            text: this.$t('card-8-text'),
            img: '/img/ar/Image-7-min.png',
            reverse: true,
            icon: 'icon-game-controller',
          },
        ],
      ];
    },

    uBadge() {
      return {
        title: this.$t('additional_module'),
        iconInside: MoreFeaturesIconsDesktop.Puzzle,
        iconOutside: MoreFeaturesIconsMobile.Puzzle,
      };
    },
  },
};
</script>
