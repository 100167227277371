<i18n src="./translations.json"></i18n>

<template>
  <section class="why-apps">
    <div class="why-apps__heading">
      <h2 class="section__title">{{ $t('title') }}</h2>
      <UTabs :tabs="tabs">
        <template #club>
          <div class="why-apps__content-item">
            <div class="why-apps__content-cell" :key="i" v-for="(item, i) in tabs[0].content">
              <i :class="`icon-${item.icon} why-apps__content-icon`"></i>
              <p class="why-apps__content-text">
                <span class="why-apps__content-text--white"> {{ item.strongText }}</span>
                {{ item.text }}
              </p>
            </div>
          </div>
          <!-- /.why-apps__content-item -->
        </template>
        <template #partners>
         <div class="why-apps__content-item">
           <div class="why-apps__content-cell" :key="i" v-for="(item, i) in tabs[1].content">
             <i :class="`icon-${item.icon} why-apps__content-icon`"></i>
             <p class="why-apps__content-text">
               <span class="why-apps__content-text--white"> {{ item.strongText }}</span>
               {{ item.text }}
             </p>
           </div>
         </div>
         <!-- /.why-apps__content-item -->
        </template>
        <template #fans>
          <div class="why-apps__content-item">
            <div class="why-apps__content-cell" :key="i" v-for="(item, i) in tabs[2].content">
              <i :class="`icon-${item.icon} why-apps__content-icon`"></i>
              <p class="why-apps__content-text">
                <span class="why-apps__content-text--white"> {{ item.strongText }}</span>
                {{ item.text }}
              </p>
            </div>
          </div>
          <!-- /.why-apps__content-item -->
        </template>
      </UTabs>
    </div>
  </section>
</template>

<script>
import UTabs from '@/components/U/Tabs/UTabs';

export default {
  name: 'WhyApps',
  components: { UTabs },
  data() {
    return {
      selectedTab: null,
    };
  },
  computed: {
    selectedTabContent() {
      return this.selectedTab?.content || this.tabs[0].content;
    },
    tabs: {
      get() {
        return [
          {
            id: 0,
            title: 'club',
            icon: 'Team',
            label: this.$t('tab-1-label'),
            labelMini: this.$t('tab-1-label-mini'),
            selected: true,
            content: [
              {
                icon: 'speakerphone',
                strongText: this.$t('tab-1-title-1'),
                text: this.$t('tab-1-text-1'),
              },
              {
                icon: 'collection',
                strongText: this.$t('tab-1-title-2'),
                text: this.$t('tab-1-text-2'),
              },
              {
                icon: 'puzzle',
                strongText: this.$t('tab-1-title-3'),
                text: this.$t('tab-1-text-3'),
              },
              {
                icon: 'currency-dollar',
                strongText: this.$t('tab-1-title-4'),
                text: this.$t('tab-1-text-4'),
              },
              {
                icon: 'trend-up-01',
                strongText: this.$t('tab-1-title-5'),
                text: this.$t('tab-1-text-5'),
              },
              {
                icon: 'badge-check',
                strongText: this.$t('tab-1-title-6'),
                text: this.$t('tab-1-text-6'),
              },
            ],
          },
          {
            id: 1,
            title: 'partners',
            icon: 'Partners',
            label: this.$t('tab-2-label'),
            labelMini: this.$t('tab-2-label-mini'),
            selected: false,
            content: [
              {
                icon: 'speakerphone',
                strongText: this.$t('tab-2-title-1'),
                text: this.$t('tab-2-text-1'),
              },
              {
                icon: 'chart-bar',
                strongText: this.$t('tab-2-title-2'),
                text: this.$t('tab-2-text-2'),
              },
              {
                icon: 'light-bulb',
                strongText: this.$t('tab-2-title-3'),
                text: this.$t('tab-2-text-3'),
              },
            ],
          },
          {
            id: 2,
            title: 'fans',
            icon: 'Fans',
            label: this.$t('tab-3-label'),
            labelMini: this.$t('tab-3-label-mini'),
            selected: false,
            content: [
              {
                icon: 'device-mobile',
                strongText: this.$t('tab-3-title-1'),
                text: this.$t('tab-3-text-1'),
              },
              {
                icon: 'fire',
                strongText: this.$t('tab-3-title-2'),
                text: this.$t('tab-3-text-2'),
              },
              {
                icon: 'chat-alt-2',
                strongText: this.$t('tab-3-title-3'),
                text: this.$t('tab-3-text-3'),
              },
              {
                icon: 'status-online',
                strongText: this.$t('tab-3-title-4'),
                text: this.$t('tab-3-text-4'),
              },
            ],
          },
        ];
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    tabClick(tab) {
      this.selectedTab = tab;
      this.tabs = this.tabs.map((item) => (item.id === tab.id
        ? { ...item, selected: true }
        : { ...item, selected: false }));
    },
  },
};
</script>

<style scoped lang="scss">
@import "./why-apps.scss";
</style>
