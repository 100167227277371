import Facebook from './icons/facebook-414.svg';
import Adfox from './icons/adfox-414.svg';
import AppMetrica from './icons/app-metrica-414.svg';
import AppsFlyer from './icons/apps-flyer-414.svg';
import Opta from './icons/opta-414.svg';
import GoogleAnalytics from './icons/google-analytics-414.svg';
import InStat from './icons/in-stat-414.svg';
import Manzana from './icons/manzana-414.svg';
import MicrosoftDynamicsCrm from './icons/microsoft-dynamics-crm-414.svg';
import SAPCrm from './icons/sap-crm-414.svg';
import SportRadar from './icons/sport-radar-414.svg';
import WyScout from './icons/wy-scout-414.svg';

const IconsPackMobile = Object.freeze({
  Facebook,
  Adfox,
  AppMetrica,
  AppsFlyer,
  Opta,
  GoogleAnalytics,
  InStat,
  Manzana,
  MicrosoftDynamicsCrm,
  SAPCrm,
  SportRadar,
  WyScout,
});

export default IconsPackMobile;
