<template>
  <div class="base-page">
    <header class="base-page__header">
      <img src="/img/ar/ar-bg.svg" alt="" class="base-page__header-bg">

      <slot name="caption" />
      <h1 class="base-page__header-title">
        <slot name="h1" />
      </h1>
    </header>

    <aside>
      <div class="container">
        <slot name="aside" />
      </div>
    </aside>

    <main class="container">
      <slot />
    </main>

    <aside class="base-page__preend">
      <slot name="preend" />
    </aside>
  </div>
</template>

<script>
export default {
  name: 'BasePage',
};
</script>

<style lang="scss" scoped>
@import "./base-page.scss";
</style>
