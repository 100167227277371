<i18n src="./header.json"></i18n>

<template>
  <div class="header">
    <div class="header__container">
      <div class="header__logo">
        <router-link to="/">
          <CrespoLogo/>
        </router-link>
      </div>
      <div
        class="header__main"
        :class="{'header__main--active': showMenu}"
      >
        <NavigationHeader @toggle-menu="toggleMenu"/>
      </div>
      <div class="header__contacts">
        <TogglerLang/>
        <UButtonMenu @toggle-menu="toggleMenu" :show-menu="showMenu"/>
        <UButton
          theme="success"
          size="sm"
          easy-add="#modal"
          easy-class="modal--show"
          class="header__button-contact"
        >
          {{ $tc('contact-us') }}
        </UButton>
      </div>
    </div>
    <p class="horizontal-line"/>
  </div>
</template>

<script>
import NavigationHeader from '@/components/Navigations/Header/NavigationHeader';
import TogglerLang from '@/components/Togglers/TogglerLang';
import UButton from '@/components/U/Button/UButton';
import UButtonMenu from '@/components/U/ButtonMenu/UButtonMenu';
import CrespoLogo from '@/assets/logo/crespo.svg';

export default {
  name: 'HeaderContainer',
  components: {
    NavigationHeader,
    TogglerLang,
    UButton,
    CrespoLogo,
    UButtonMenu,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleContact() {
      console.log(1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./header.scss";
</style>
