<i18n src="./translations.json"></i18n>

<template>
  <div class="tags-slider">
    <img :src="$t('tags')" alt="" class="tags-slider__img">
  </div>
</template>

<script>
export default {
  name: 'TagsSlider',
};
</script>

<style scoped lang="scss">
@import "./tags-slider.scss";
</style>
