<template>
  <section
    class="sec-card"
    :style="{
      flexFlow: reverse ? 'row-reverse nowrap' : false,
    }"
  >
    <img
      :src="img"
      alt=""
      class="sec-card__img"
    >

    <div class="sec-card__content">
      <span class="sec-card__caption"><slot name="caption"/></span>
      <h2 class="sec-card__title"><slot name="title"/></h2>
      <p class="sec-card__text"><slot name="text"/></p>
    </div>
    <!-- /.sec-card__content -->
  </section>
</template>

<script>
export default {
  name: 'SecCard',
  props: ['img', 'reverse'],
};
</script>

<style lang="scss">
@import "./sec-card.scss";
</style>
