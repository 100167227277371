import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView';
import AugmentedReality from '@/views/AugmentedRealityView';
import Gamification from '@/views/GamificationView';
import Policy from '@/views/PolicyView';
import TermsAndConditions from '@/views/TermsAndConditionsView';
import CookiesPolicy from '@/views/CookiesPolicyView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/augmented-reality',
    name: 'AugmentedReality',
    component: AugmentedReality,
  },
  {
    path: '/gamification',
    name: 'Gamification',
    component: Gamification,
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/cookies-policy',
    name: 'CookiesPolicy',
    component: CookiesPolicy,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
