<i18n src="./translations.json"></i18n>

<template>
<div id="modal" class="modal">
  <div class="modal__container">
    <img src="@/assets/img/feedbackform/bg-image.png" alt="" class="modal__background">

    <div class="modal__content">
      <UButton
        theme="close"
        size="rect-24"
        easy-remove="#modal"
        easy-class="modal--show"
        class="modal__close"
      >
        <i class="icon-x-close"></i>
      </UButton>

      <div class="modal__text">
        <span class="modal__text-title">
          {{ $t('title') }}
        </span>

        <span class="modal__text-description">
          {{ $t('subtitle') }}
        </span>
      </div>

      <FeedbackForm />
    </div>
    <!-- /.modal__content -->
  </div>

  <div
    class="modal__overlay"
    easy-remove="#modal"
    easy-class="modal--show"
  />
</div>
</template>

<script>
import FeedbackForm from '@/components/FeedbackForm/FeedbackForm';
import UButton from '@/components/U/Button/UButton';

export default {
  name: 'ModalContainer',
  components: { FeedbackForm, UButton },
};
</script>

<style scoped lang="scss">
@import "./modal.scss";
</style>
