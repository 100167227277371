<i18n src="./translations.json"></i18n>

<template>
  <div class="flex column items-center gap-64 p-0">
    <p class="integrations__title">{{ $t('title') }}</p>
    <div class="integrations__grid">
      <div
        class="integrations__grid-cell"
        :key="i"
        v-for="(int, i) in integrations"
        @click="integrationClicked(int.url)"
      >
        <component :is="int.icon"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationsContainer',
  props: {
    integrations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    integrationClicked(url) {
      if (!url) return;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
@import "./integrations.scss";
</style>
