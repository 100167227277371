<i18n src="./hero.json"></i18n>

<template>
  <div class="hero">
    <img src="@/assets/img/hero/hero-bg.png" alt="" class="hero-background">
    <img src="@/assets/img/hero/hero-bg-mobile.png" alt="" class="hero-background--mobile">
    <div class="hero__bg"/>
    <div class="container">
      <div class="hero__container">
        <div class="hero__bg--phone  hero__bg--hidden-mobile">
          <img alt="" :src="$t('img_left')">
        </div>
        <div class="hero__bg--mobile">
          <img alt="" :src="$t('img_left')">
          <img alt="" :src="$t('img_right')">
        </div>
        <div class="flex-col hero__center">
          <p
            class="hero hero__title"
            v-html="$t('mobile-app-club')"
          ></p>
          <div>
            <p
              class="hero hero__description"
              v-html="$t('flexible-platform')"
            ></p>
            <p class="hero hero__description">
              {{ $t('for') }}
              <strong>
                {{ $t('two-weeks') }}
              </strong>
              {{ $t('from') }}
              <strong>
                {{ $t('$-500-month') }}
              </strong>
            </p>
          </div>
          <UButton
            theme="success"
            size="md"
            easy-add="#modal"
            easy-class="modal--show"
          >
            {{ $t('contact-us') }}
          </UButton>
        </div>
        <div class="hero__bg--phone  hero__bg--hidden-mobile">
          <img alt="" :src="$t('img_right')">
        </div>
      </div>
      <!-- /.hero__container -->
    </div>
    <!-- /.container -->
  </div>
</template>

<script>
import UButton from '@/components/U/Button/UButton';

export default {
  name: 'HeroContainer',
  components: { UButton },
};
</script>

<style scoped lang="scss">
@import "./hero";
</style>
