import Facebook from './icons/facebook.svg';
import Adfox from './icons/adfox.svg';
import AppMetrica from './icons/app-metrica.svg';
import AppsFlyer from './icons/apps-flyer.svg';
import Opta from './icons/opta.svg';
import GoogleAnalytics from './icons/google-analytics.svg';
import InStat from './icons/in-stat.svg';
import Manzana from './icons/manzana.svg';
import MicrosoftDynamicsCrm from './icons/microsoft-dynamics-crm.svg';
import SAPCrm from './icons/sap-crm.svg';
import SportRadar from './icons/sport-radar.svg';
import WyScout from './icons/wy-scout.svg';

const IconsPackDesktop = Object.freeze({
  Facebook,
  Adfox,
  AppMetrica,
  AppsFlyer,
  Opta,
  GoogleAnalytics,
  InStat,
  Manzana,
  MicrosoftDynamicsCrm,
  SAPCrm,
  SportRadar,
  WyScout,
});

export default IconsPackDesktop;
